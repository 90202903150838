


















































































































































































































































































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AudioTrackV2 from '@/components/Audio/AudioTrackV2.vue'
import YoutubeAudioTrack from '@/components/Audio/YoutubeAudioTrack.vue'
import YoutubeAllEpisodes from '@/components/Audio/YoutubeAllEpisodes.vue'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import PodcastSelector from '@/components/Podcast/PodcastSelector.vue'
import SearchAndSelectTray from '@/components/Common/SearchAndSelectTray.vue'
import PodcastDetailsCard from '@/components/Podcast/PodcastDetailsCard.vue'
import PodcastDirectories from '@/components/Podcast/PodcastDirectories.vue'
import ShowSelector from '@/components/Audio/Panes/ShowSelector.vue'
import isEmpty from 'lodash.isempty'
import get from 'lodash.get'
import TheConfetti from '@/components/Common/TheConfetti.vue'
import InformationTray from '../Common/InformationTray.vue'
import { computed, reactive, SetupContext } from '@vue/composition-api'
import { audioQueryKeys, trackType, useGetFeedTracks } from '@/hooks/audio'
import { useGetYoutubeSummary } from '@/hooks/youtube'
import Billing from '@/mixins/Billing'
import { QueryClient, useQueryClient } from 'vue-query'

@Component({
  components: {
    TheMainPage,
    AudioTrackV2,
    YoutubeAudioTrack,
    PodcastSelector,
    InformationTray,
    SearchAndSelectTray,
    PodcastDetailsCard,
    PodcastDirectories,
    ShowSelector,
    YoutubeAllEpisodes,
    TheConfetti,
  },
  setup(props: any, context: SetupContext) {
    const queryClient = useQueryClient()
    const selectedPodcastUid: any = computed(() => props.selectedPodcast.uid)
    const isYoutube = computed(() => !!props.fromYoutube)
    const feedTrackParams: any = reactive({
      rssFeedUid: selectedPodcastUid,
      limit: 10,
      offset: 0,
      order: 'recent_first',
      filter: 'all',
      fromYoutube: isYoutube,
    })

    const youtubeParams: any = reactive({
      showId: selectedPodcastUid,
    })

    const { data: feedTracksData, isFetching: feedTracksLoading } = useGetFeedTracks(
      trackType.PUBLISHED,
      selectedPodcastUid,
      { enabled: true },
      feedTrackParams
    )

    const { data: feedTracksUnpublishedData, isFetching: feedTracksUnpublishedLoading } = useGetFeedTracks(
      trackType.UNPUBLISHED,
      selectedPodcastUid,
      { enabled: isYoutube },
      feedTrackParams
    )

    const { data: feedAllTrackData, isFetching: feedAllTracksLoading } = useGetFeedTracks(
      trackType.ALL,
      selectedPodcastUid,
      { enabled: isYoutube },
      feedTrackParams
    )

    useGetYoutubeSummary(youtubeParams)

    return {
      queryClient,
      feedTrackParams,
      feedTracksData,
      feedTracksLoading,
      feedTracksUnpublishedData,
      feedTracksUnpublishedLoading,
      feedAllTrackData,
      feedAllTracksLoading,
    }
  },
})
export default class AllPodcastsPage extends Mixins(Billing) {
  @Prop(Boolean) selectable!: boolean
  @Prop(String) selectionType!: string // One of 'SINGLE' or 'MULTI'
  @Prop(Boolean) editable!: boolean
  @Prop(String) collectionId!: string
  @Prop(Boolean) showEditButtons!: boolean
  @Prop(Boolean) showPreviewButton!: boolean
  @Prop(Boolean) showDownloadButton!: boolean
  @Prop(Boolean) isPublishPage!: boolean
  @Prop(Boolean) fromYoutube!: boolean
  @Prop() selectedPodcast!: any
  @Prop() rssFeedsData!: any

  @Getter showPane!: any
  @Getter selectedEpisodeList!: any
  @Getter podcastPageNumber!: any
  @Getter multiUploadCounter!: any
  @Getter networkId!: any
  @Getter selectedAudioUids!: any

  @Action setShowPane!: any
  @Action getPublicFeedEpisodes!: any
  @Action setPodcastPageNumber!: any
  @Action toggleCreditCardAlert!: any
  @Action setShowLoader!: any
  @Action removeRssFeedItem!: any
  @Action publishRssFeed!: any
  @Action unselectAllAudios!: any

  showSearchTray: boolean = false
  selectedPodcastPublishStatus: string = 'ALL_EPISODES' // One of 'ALL_EPISODES', 'ALL_PUBLISHED', 'ALL_SCHEDULED'
  activeTab: string = 'ALL_PUBLISHED'
  pageNo = 1
  increment = 10
  timer: any = 0
  showConfetti = false
  confettiCounter = false

  feedTrackParams!: any
  feedTracksData!: any
  feedTracksLoading!: any
  feedTracksUnpublishedData!: any
  feedTracksUnpublishedLoading!: any
  feedAllTrackData!: any
  feedAllTracksLoading!: any

  onboarding = false
  queryClient!: QueryClient

  get loaders() {
    if (
      this.podcastPageNumber[this.selectedPodcast.uid][
        this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus
      ] === this.pageCount
    ) {
      const noOfLoader = +((this.fromYoutube ? this.ytTotalPublished : 0 / 10) % 1).toFixed(1) * 10
      return noOfLoader === 0 ? new Array(10) : new Array(noOfLoader)
    } else {
      return new Array(10)
    }
  }

  get showNoEpisodes() {
    switch (this.activeTab) {
      case 'ALL_PUBLISHED':
        return this.ytTotalPublished === 0
      case 'ALL_UNPUBLISHED':
        return this.ytTotalUnPublished === 0
      case 'ALL_EPISODES':
        return this.ytTotalAllTracks === 0
      default:
        return false
    }
  }

  get totalEpisodes() {
    return this.totalPublishedEpisodes + this.totalScheduledEpisodes
  }

  get totalPublishedEpisodes() {
    return this.selectedPodcast.totalPublishedTracks
  }

  get totalScheduledEpisodes() {
    return this.selectedPodcast.totalScheduledTracks
  }

  created() {
    this.$store.commit('unselectAllEpisodeForUpload')
    if (isEmpty(this.podcastPageNumber) || isEmpty(this.podcastPageNumber[this.selectedPodcast.uid])) {
      this.setPodcastPageNumber({
        [`${this.selectedPodcast.uid}`]: {
          [`${this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus}`]: 1,
        },
      })
    }

    this.$watch(
      () =>
        this.podcastPageNumber[this.selectedPodcast.uid][
          this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus
        ],
      () => {
        this.feedTrackParams.offset =
          (get(
            this.podcastPageNumber[this.selectedPodcast.uid],
            [this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus],
            1
          ) -
            1) *
          this.increment
      }
    )
    if (this.$route.query.active) {
      this.activeTab = this.$route.query.active
      this.$router.push(`/home/podcasts/${this.selectedPodcast.uid}`)
    }
  }

  @Watch('activeTab')
  @Watch('selectedPodcast')
  async onActiveTabChanged() {
    if (isEmpty(this.podcastPageNumber[this.selectedPodcast.uid])) {
      this.setPodcastPageNumber({
        [`${this.selectedPodcast.uid}`]: {
          [`${this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus}`]: 1,
        },
      })
    }
  }

  @Watch('selectedPodcastPublishStatus')
  async onPublishStatusChanged() {
    if (!this.podcastPageNumber[this.selectedPodcast.uid][this.selectedPodcastPublishStatus]) {
      this.setPodcastPageNumber({
        [`${this.selectedPodcast.uid}`]: {
          [`${this.selectedPodcastPublishStatus}`]: 1,
        },
      })
    }
  }
  @Watch('multiUploadCounter')
  handleMultiUploadCounter() {
    if (this.multiUploadCounter.length > 0) {
      this.confettiCounter = true
    }
    if (!this.multiUploadCounter.length && this.confettiCounter) {
      this.showConfetti = true
      this.confettiCounter = false
      setTimeout(() => {
        this.showConfetti = false
      }, 10000)
    }
  }

  customLoaders(number = 10) {
    return new Array(number)
  }

  get ytTotalPublished() {
    return this.feedTracksData?.count
  }

  get ytTotalUnPublished() {
    return this.feedTracksUnpublishedData?.length
  }
  get ytTotalAllTracks() {
    return this.feedAllTrackData?.length
  }

  get showMultiSelectMenu() {
    return (
      this.fromYoutube &&
      (this.activeTab === 'ALL_UNPUBLISHED' || this.activeTab === 'ALL_EPISODES') &&
      this.selectedEpisodeList.length > 0
    )
  }

  get showMultiDeleteMenu() {
    return (
      !this.fromYoutube &&
      (this.activeTab === 'ALL_PUBLISHED' || this.activeTab === 'ALL_EPISODES') &&
      this.selectedAudioUids.length > 0
    )
  }

  get showPagination() {
    return this.activeTab === 'ALL_UNPUBLISHED' || this.activeTab === 'ALL_EPISODES' ? false : true
  }

  get pageCount() {
    return Math.ceil(this.ytTotalPublished / 10)
  }

  unselectAll() {
    this.$store.commit('unselectAllEpisodeForUpload')
  }

  changePublishStatus(status: string) {
    this.selectedPodcastPublishStatus = status
    this.feedTrackParams.filter =
      status === 'ALL_EPISODES' ? 'all' : status === 'ALL_PUBLISHED' ? 'published' : 'scheduled'
  }

  async selectRssFeedUid(rssFeedUid: string) {
    this.$emit('selectRss', rssFeedUid)
    this.unselectAll()
    this.unselectAllAudios()
    this.showSearchTray = false
  }

  async next() {
    if (
      this.podcastPageNumber[this.selectedPodcast.uid][
        this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus
      ] +
        1 <=
      this.pageCount
    ) {
      this.setPodcastPageNumber({
        [`${this.selectedPodcast.uid}`]: {
          [`${this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus}`]:
            this.podcastPageNumber[this.selectedPodcast.uid][
              this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus
            ] + 1,
        },
      })
    }
  }

  async prev() {
    if (
      this.podcastPageNumber[this.selectedPodcast.uid][
        this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus
      ] > 1
    ) {
      this.setPodcastPageNumber({
        [`${this.selectedPodcast.uid}`]: {
          [`${this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus}`]:
            this.podcastPageNumber[this.selectedPodcast.uid][
              this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus
            ] - 1,
        },
      })
    }
  }

  async OnPageNumberChanged(page: any) {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(async () => {
      if (page <= this.pageCount && page > 0) {
        this.pageNo = parseInt(page)
        this.setPodcastPageNumber({
          [`${this.selectedPodcast.uid}`]: {
            [`${this.fromYoutube ? this.activeTab : this.selectedPodcastPublishStatus}`]: +page,
          },
        })
      }
    }, 500)
  }

  async handleRemoveMultiEpisodes() {
    this.feedTracksLoading = true
    try {
      for (let index = 0; index < this.selectedAudioUids.length; index++) {
        await this.removeRssFeedItem({
          rssFeedUid: this.selectedPodcast.uid,
          rssFeedItemUid: this.selectedAudioUids[index],
          deleteTrack: true,
        })
      }
      await this.publishRssFeed({
        rssFeedUid: this.selectedPodcast.uid,
      })
      this.unselectAllAudios()
    } catch (err) {
      this.feedTracksLoading = false
    }
    this.invalidate()
    this.feedTracksLoading = false
  }

  invalidate() {
    this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
    this.queryClient.invalidateQueries([audioQueryKeys.YTPUBLISHED, `${this.selectedPodcast.uid}`])
  }

  async goToOnboarding() {
    if (this.showPopup) {
      this.toggleCreditCardAlert(true)
      return 0
    }
    this.onboarding = true
    this.$store.commit('setCreatedYoutubeFeed', this.selectedPodcast)
    await this.getPublicFeedEpisodes({
      url: this.selectedPodcast.rss,
    })
    sessionStorage.setItem('feedUrl', this.selectedPodcast.rss)
    this.$store.commit('clearYoutubeState')
    this.$store.commit('resetYoutubeSetting')
    this.$store.commit('setYoutubeStep', 2)
    // @ts-ignore
    sessionStorage.setItem('upload', 'YES')
    this.$router.push('/onboarding')
    this.onboarding = false
  }
}









































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import moment from 'moment'

@Component
export default class ShowSelector extends Vue {
  @Prop() list!: any
  @Prop() selectedItem!: string
  @Prop() onSelectItem!: (onSelectItem: string) => void
  @Prop() onClose!: () => void
  @Prop({ default: false })
  isVisible!: boolean
  @Prop({ default: false })
  isLoading!: boolean

  @Action setRssFeedsSortOrder!: any
  @Action setShowPane!: any

  search = ''
  podcastSortOrder: string = ''

  get filteredList() {
    const list = [...this.list]
    let filteredList = []
    switch (this.podcastSortOrder) {
      case 'latestCreated':
        filteredList = list.sort((a: any, b: any) => moment(a.createdOn).unix() - moment(b.createdOn).unix()).reverse()
        break
      case 'AZ':
        filteredList = list.sort((a: any, b: any) => (a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1))
        break
      case 'ZA':
        filteredList = list.sort((a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1))
        break
      default:
        filteredList = list
    }
    if (this.search) {
      return filteredList.filter((feed: any) => feed.name.toLowerCase().includes(this.search.toLowerCase()))
    }
    return filteredList
  }

  onCloseTray() {
    this.search = ''
    this.onClose()
  }

  mounted() {
    this.setRssFeedsSortOrder('')
    this.search = ''
  }

  beforeDestroy() {
    // this.setRssFeedsSortOrder('')
  }

  setSortOrder(sortOrder: string) {
    this.podcastSortOrder = sortOrder
    // this.setRssFeedsSortOrder(sortOrder)
    // this.$emit('emitCheck')
  }

  clearSearch() {
    this.search = ''
  }

  @Watch('isVisible')
  onVisible() {
    this.setShowPane(this.isVisible)
  }
}

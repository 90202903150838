var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "div",
        {
          staticClass: "justify-between items-center br2 ba b--adori-gray mb1",
        },
        [
          _vm.isLoading
            ? _c(
                "div",
                {
                  staticClass: "bg-adori-light-gray bn br2 pa3 tc w-100 white",
                },
                [_vm._v("Please wait while we load data...")]
              )
            : _c("div", [
                _c("div", { staticClass: "backButton" }, [
                  _vm.isBackVisible
                    ? _c(
                        "i",
                        {
                          staticClass:
                            "material-icons mdc-text-field__icon fl pointer white",
                          attrs: { tabindex: "0", role: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.onBack()
                            },
                          },
                        },
                        [_vm._v("arrow_back")]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "bg-adori-light-gray bn br2 pa3 tc w-100" },
                  [
                    _vm.showCarousal &&
                    _vm.fromYoutube &&
                    _vm.$permissions.isDeletePodcastShowAllowed(
                      _vm.selectedItem
                    )
                      ? _c(
                          "div",
                          { staticClass: "relative w-100" },
                          [
                            _c(
                              "div",
                              { staticClass: "absolute left-0" },
                              [
                                _c("BaseButtonBorder", {
                                  staticClass: "mr2 b-red btn-hover",
                                  attrs: {
                                    icon: _vm.automationIcon,
                                    text: _vm.publishStatusText,
                                    disabled:
                                      _vm.isAutomationAdded &&
                                      !_vm.isAutomationsEnabled,
                                    onClick: _vm.handleAddAutomation,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("BasePopupMenu", {
                              staticClass: "absolute right-0",
                              attrs: { icon: "more_vert", items: _vm.showMenu },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isInline
                      ? _c(
                          "div",
                          { staticClass: "flex justify-center items-center" },
                          [
                            _vm.showCarousal &&
                            _vm.list.length !== 1 &&
                            !_vm.isBackVisible &&
                            _vm.showPrev
                              ? _c("img", {
                                  staticClass: "pointer",
                                  style: { width: "24px" },
                                  attrs: {
                                    src: require("@/assets/icons/previous_active.svg"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSelectPrev()
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.showCarousal &&
                            _vm.list.length !== 1 &&
                            !_vm.isBackVisible &&
                            !_vm.showPrev
                              ? _c("img", {
                                  style: { width: "24px" },
                                  attrs: {
                                    src: require("@/assets/icons/previous_inactive.svg"),
                                  },
                                })
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-center items-center ph5",
                                style: { width: "550px", maxWidth: "550px" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "bg-black tc dib",
                                    style: {
                                      width: _vm.imgWidth + "px",
                                      minWidth: _vm.imgWidth + "px",
                                      height: _vm.imgHeight + "px",
                                    },
                                  },
                                  [
                                    _vm.list &&
                                    _vm.list[_vm.selectedItemIndex] &&
                                    _vm.list[_vm.selectedItemIndex].imageUrl
                                      ? _c("img", {
                                          style: {
                                            height: _vm.imgHeight + "px",
                                          },
                                          attrs: {
                                            src: _vm.list[_vm.selectedItemIndex]
                                              .imageUrl,
                                          },
                                        })
                                      : _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons light-gray bg-adori-very-light-gray flex justify-center items-center icon",
                                            style: {
                                              height: _vm.imgHeight + "px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              audiotrack\n            "
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "light-gray ph3 pv2 f5 dib b-color tl",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.list &&
                                            _vm.list[_vm.selectedItemIndex] &&
                                            _vm.list[_vm.selectedItemIndex].name
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm.showCarousal &&
                            _vm.list.length !== 1 &&
                            !_vm.isBackVisible &&
                            _vm.showNext
                              ? _c("img", {
                                  staticClass: "pointer",
                                  style: { width: "24px" },
                                  attrs: {
                                    src: require("@/assets/icons/next_active.svg"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSelectNext()
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.showCarousal &&
                            _vm.list.length !== 1 &&
                            !_vm.isBackVisible &&
                            !_vm.showNext
                              ? _c("img", {
                                  style: { width: "24px" },
                                  attrs: {
                                    src: require("@/assets/icons/next_inactive.svg"),
                                  },
                                })
                              : _vm._e(),
                          ]
                        )
                      : _c("div", [
                          _vm.showCarousal &&
                          _vm.list.length !== 1 &&
                          !_vm.isBackVisible &&
                          _vm.showPrev
                            ? _c("img", {
                                staticClass: "pointer mr6",
                                style: { width: "24px" },
                                attrs: {
                                  src: require("@/assets/icons/previous_active.svg"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onSelectPrev()
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.showCarousal &&
                          _vm.list.length !== 1 &&
                          !_vm.isBackVisible &&
                          !_vm.showPrev
                            ? _c("img", {
                                staticClass: "mr6",
                                style: { width: "24px" },
                                attrs: {
                                  src: require("@/assets/icons/previous_inactive.svg"),
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "bg-black tc dib",
                              class: _vm.isBackVisible ? "backImage" : "",
                              style: {
                                width: _vm.imgWidth + "px",
                                minWidth: _vm.imgWidth + "px",
                                height: _vm.imgHeight + "px",
                              },
                            },
                            [
                              _vm.list &&
                              _vm.list[_vm.selectedItemIndex] &&
                              _vm.list[_vm.selectedItemIndex].imageUrl
                                ? _c("img", {
                                    style: { height: _vm.imgHeight + "px" },
                                    attrs: {
                                      src: _vm.list[_vm.selectedItemIndex]
                                        .imageUrl,
                                    },
                                  })
                                : _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons light-gray bg-adori-very-light-gray flex justify-center items-center icon",
                                      style: { height: _vm.imgHeight + "px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n            audiotrack\n          "
                                      ),
                                    ]
                                  ),
                            ]
                          ),
                          _vm.showCarousal &&
                          _vm.list.length !== 1 &&
                          !_vm.isBackVisible &&
                          _vm.showNext
                            ? _c("img", {
                                staticClass: "pointer ml6",
                                style: { width: "24px" },
                                attrs: {
                                  src: require("@/assets/icons/next_active.svg"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onSelectNext()
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.showCarousal &&
                          _vm.list.length !== 1 &&
                          !_vm.isBackVisible &&
                          !_vm.showNext
                            ? _c("img", {
                                staticClass: "ml6",
                                style: { width: "24px" },
                                attrs: {
                                  src: require("@/assets/icons/next_inactive.svg"),
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "light-gray ph3 pv2 f5 db b-color" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.list &&
                                      _vm.list[_vm.selectedItemIndex] &&
                                      _vm.list[_vm.selectedItemIndex].name
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]),
                    _vm.isButtonVisible
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "f7 br2 bn pv2 light-gray ph3 pv1 bg-adori-red btn-shadow o-90 glow pointer",
                            on: { click: _vm.onButtonClick },
                          },
                          [
                            _vm._v(
                              "\n        " + _vm._s(_vm.buttonName) + "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
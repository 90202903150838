




































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { toReadableDate } from '@/utils/time'
import { secondsToHMS } from '@/components/Youtube/time'

@Component({
  components: {},
})
export default class YoutubeAllEpisodes extends Vue {
  @Prop(String) audioUid!: string
  @Prop(Boolean) selectable!: boolean
  @Prop(String) selectionType!: string // One of 'SINGLE' or 'MULTI'
  @Prop(Object) episode!: any
  @Prop(Object) podcast!: any
  @Prop(String) rss!: string

  @Getter publicFeedEpisodes!: any
  @Getter selectedEpisodeList!: any
  @Getter isEpisodeSelectedForUpload!: any

  @Action getPublicFeedEpisodes!: any
  @Action toggleSelectEpisodeForUpload!: any

  get audio() {
    return this.episode
  }

  get audioPreviewImage() {
    return this.audio
      ? this.audio.thumbnail
        ? this.audio.thumbnail
        : this.audio.imageUrl
        ? this.audio.imageUrl
        : this.podcast.imageUrl
      : null
  }

  get audioDuration() {
    if (this.audio.durationMillis) {
      return secondsToHMS(this.audio.durationMillis / 1000)
    }
    return 'Calculating...'
  }

  get audioDate() {
    if (this.audio.publishedOn) {
      return toReadableDate(this.audio.publishedOn)
    }

    if (this.audio.publishTime) {
      return toReadableDate(this.audio.publishTime)
    }
    return toReadableDate(this.audio.createdOn)
  }

  get audioIsPublic() {
    return this.audio.public
  }

  get selectionIcon() {
    return this.isEpisodeSelectedForUpload(this.audio.guid) ? 'check_circle' : 'radio_button_unchecked'
  }

  get selectionStyle() {
    return this.isEpisodeSelectedForUpload(this.audio.guid) ? 'adori-red' : 'o-20'
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  handleAudioSelectionIconClicked() {
    if (this.selectable) {
      if (this.selectionType === 'SINGLE') {
        this.$store.dispatch('unselectAllAudios')
        this.$store.dispatch('toggleSelectAudio', this.audioUid)
      }
      if (this.selectionType === 'MULTI' && this.selectedEpisodeList.length <= 9) {
        this.toggleSelectEpisodeForUpload({ isRss: true, ...this.audio })
      } else {
        this.$store.commit('unselectEpisodesForUpload', this.audio)
      }
    }
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("div", [
        _c(
          "div",
          [
            _c(
              "PodcastSelector",
              {
                key: _vm.selectedPodcast.uid,
                attrs: { podcastUid: _vm.selectedPodcast.uid },
              },
              [
                _c("InformationTray", {
                  ref: "_informatinTray",
                  attrs: {
                    isVisible: _vm.rssFeedsData.length !== 0,
                    isBackVisible: false,
                    onBackClick: _vm.selectRssFeedUid,
                    list: _vm.rssFeedsData,
                    selectedItem: _vm.selectedPodcast.uid,
                    isButtonVisible: _vm.rssFeedsData.length > 1,
                    buttonName: "Choose show",
                    onButtonClick: function () {
                      _vm.showSearchTray = true
                      _vm.setShowPane(!_vm.showPane)
                    },
                    showCarousal: true,
                    onChangeCarousal: _vm.selectRssFeedUid,
                    count: _vm.rssFeedsData.count,
                    rssURL: _vm.selectedPodcast.rss,
                    fromYoutube: _vm.fromYoutube,
                    podcast: _vm.selectedPodcast,
                  },
                }),
              ],
              1
            ),
            _c("ShowSelector", {
              ref: "_searchAndSelectTray",
              attrs: {
                isVisible: _vm.rssFeedsData.length > 1 && _vm.showSearchTray,
                list: _vm.rssFeedsData,
                selectedItem: _vm.selectedPodcast.uid,
                onSelectItem: _vm.selectRssFeedUid,
                onClose: function () {
                  _vm.showSearchTray = false
                },
              },
            }),
            !_vm.fromYoutube
              ? _c("PodcastDetailsCard", {
                  attrs: { podcast: _vm.selectedPodcast },
                })
              : _vm._e(),
            _vm.isPublishPage ? _c("PodcastDirectories") : _vm._e(),
            !_vm.fromYoutube && !_vm.isPublishPage
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex bg-adori-light-gray ba justify-between items-center pl4 pr3 pt3 bb b--adori-gray br2 br-top",
                  },
                  [
                    _c("div", { staticClass: "flex items-center mb2" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "f3 fw1 mr4 light-gray flex items-center",
                        },
                        [_vm._v("Episodes")]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mr2 flex items-center justify-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex items-center justify-start w4" },
                          [
                            _c("BaseButtonTab", {
                              class:
                                _vm.selectedPodcastPublishStatus ===
                                "ALL_EPISODES"
                                  ? ""
                                  : "bb b--adori-transparent bw1",
                              attrs: {
                                text: "All episodes",
                                active:
                                  _vm.selectedPodcastPublishStatus ===
                                  "ALL_EPISODES",
                                onClick: function () {
                                  return _vm.changePublishStatus("ALL_EPISODES")
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f7 bg-adori-very-light-gray br3 white ml1",
                                staticStyle: {
                                  padding: "2px 5px",
                                  "margin-bottom": "2px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.totalEpisodes) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex items-center justify-start w4" },
                          [
                            _c("BaseButtonTab", {
                              class:
                                _vm.selectedPodcastPublishStatus ===
                                "ALL_PUBLISHED"
                                  ? ""
                                  : "bb b--adori-transparent bw1",
                              attrs: {
                                text: "Published",
                                active:
                                  _vm.selectedPodcastPublishStatus ===
                                  "ALL_PUBLISHED",
                                onClick: function () {
                                  return _vm.changePublishStatus(
                                    "ALL_PUBLISHED"
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f7 bg-adori-very-light-gray br3 white ml1",
                                staticStyle: {
                                  padding: "2px 5px",
                                  "margin-bottom": "2px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.totalPublishedEpisodes) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex items-center justify-start w4" },
                          [
                            _c("BaseButtonTab", {
                              class:
                                _vm.selectedPodcastPublishStatus ===
                                "ALL_SCHEDULED"
                                  ? ""
                                  : "bb b--adori-transparent bw1",
                              attrs: {
                                text: "Scheduled",
                                active:
                                  _vm.selectedPodcastPublishStatus ===
                                  "ALL_SCHEDULED",
                                onClick: function () {
                                  return _vm.changePublishStatus(
                                    "ALL_SCHEDULED"
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f7 bg-adori-very-light-gray br3 white ml1",
                                staticStyle: {
                                  padding: "2px 5px",
                                  "margin-bottom": "2px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.totalScheduledEpisodes) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.fromYoutube
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex bg-adori-light-gray ba justify-between items-center pl4 pr3 pt3 bb b--adori-gray br2 br-top",
                  },
                  [
                    _c("div", { staticClass: "flex items-center mb2" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "f3 fw1 mr4 light-gray flex items-center",
                        },
                        [_vm._v("Episodes")]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mr2 flex items-center justify-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex items-center justify-start w4" },
                          [
                            _c("BaseButtonTab", {
                              class:
                                _vm.activeTab === "ALL_PUBLISHED"
                                  ? ""
                                  : "bb b--adori-transparent bw1",
                              attrs: {
                                text: "Published",
                                active: _vm.activeTab === "ALL_PUBLISHED",
                                onClick: function () {
                                  _vm.activeTab = "ALL_PUBLISHED"
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f7 bg-adori-very-light-gray br3 white ml1",
                                staticStyle: {
                                  padding: "2px 5px",
                                  "margin-bottom": "2px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.ytTotalPublished) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex items-center justify-start w4" },
                          [
                            _c("BaseButtonTab", {
                              class:
                                _vm.activeTab === "ALL_UNPUBLISHED"
                                  ? ""
                                  : "bb b--adori-transparent bw1",
                              attrs: {
                                text: "Unpublished",
                                active: _vm.activeTab === "ALL_UNPUBLISHED",
                                onClick: function () {
                                  _vm.activeTab = "ALL_UNPUBLISHED"
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f7 bg-adori-very-light-gray br3 white ml1",
                                staticStyle: {
                                  padding: "2px 5px",
                                  "margin-bottom": "2px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.ytTotalUnPublished) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex items-center justify-start w4" },
                          [
                            _c("BaseButtonTab", {
                              class:
                                _vm.activeTab === "ALL_EPISODES"
                                  ? ""
                                  : "bb b--adori-transparent bw1",
                              attrs: {
                                text: "All episodes",
                                active: _vm.activeTab === "ALL_EPISODES",
                                onClick: function () {
                                  _vm.activeTab = "ALL_EPISODES"
                                },
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f7 bg-adori-very-light-gray br3 white ml1",
                                staticStyle: {
                                  padding: "2px 5px",
                                  "margin-bottom": "2px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.ytTotalAllTracks) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.showMultiSelectMenu
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex bg-adori-light-gray ba items-center justify-between pl4 pr3 pt1 pb1 bb b--adori-gray br2 br-top topSticky",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex items-center justify-start" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-start w4 pt3 pb3",
                          },
                          [
                            _c("div", { staticClass: "f6 white mr2" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.selectedEpisodeList.length) +
                                  "\n            "
                              ),
                            ]),
                            _c("div", { staticClass: "f6 white" }, [
                              _vm._v("Selected"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "divider ml2" }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-end" },
                      [
                        _c("BaseButtonRed", {
                          staticClass: "mr4 mv1 bg-adori o-90",
                          attrs: {
                            text: "Create Video",
                            icon: "ondemand_video",
                            onClick: _vm.goToOnboarding,
                            loading: _vm.onboarding,
                            disabled: _vm.onboarding,
                          },
                        }),
                        _c("BaseButtonRed", {
                          staticClass: "mr4 mv1 bg-adori o-90",
                          attrs: {
                            text: "Unselect All",
                            icon: "clear",
                            disabled: _vm.onboarding,
                            onClick: _vm.unselectAll,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm.showMultiDeleteMenu
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex bg-adori-light-gray ba items-center justify-between pl4 pr3 pt1 pb1 bb b--adori-gray br2 br-top topSticky",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex items-center justify-start" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-start w4 pt3 pb3",
                          },
                          [
                            _c("div", { staticClass: "f6 white mr2" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.selectedAudioUids.length) +
                                  "\n            "
                              ),
                            ]),
                            _c("div", { staticClass: "f6 white" }, [
                              _vm._v("Selected"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "divider ml2" }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-end" },
                      [
                        _c("BaseButtonRed", {
                          staticClass: "mr4 mv1 bg-adori o-90",
                          attrs: {
                            text: "Delete Multiple tracks",
                            icon: "delete",
                            onClick: _vm.handleRemoveMultiEpisodes,
                          },
                        }),
                        _c("BaseButtonRed", {
                          staticClass: "mr4 mv1 bg-adori o-90",
                          attrs: {
                            text: "Unselect All",
                            icon: "clear",
                            onClick: _vm.unselectAllAudios,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            !_vm.feedAllTracksLoading &&
            !_vm.feedTracksUnpublishedLoading &&
            !_vm.feedAllTracksLoading &&
            _vm.showNoEpisodes
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex justify-center items-center silver pa3 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
                  },
                  [_c("div", [_vm._v("No episodes found!")])]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "silver bg-adori-light-gray br2 ba b--adori-gray br2 br-bottom",
              },
              [
                _c("div", [
                  _vm.activeTab === "ALL_PUBLISHED"
                    ? _c("div", { staticClass: "relative" }, [
                        _vm.feedTracksLoading
                          ? _c(
                              "div",
                              _vm._l(_vm.loaders, function (_, index) {
                                return _c("div", {
                                  key: index,
                                  staticClass:
                                    "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton cus-size",
                                })
                              }),
                              0
                            )
                          : _vm.fromYoutube
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.feedTracksData.data || [],
                                function (track) {
                                  return _c("YoutubeAudioTrack", {
                                    key: track.uid,
                                    attrs: {
                                      sourceComponent: "PODCASTS-TAB",
                                      audioTrack: track,
                                      selectable: _vm.selectable,
                                      selectionType: _vm.selectionType,
                                      editable: _vm.editable,
                                      podcast: _vm.selectedPodcast,
                                      showEditButtons: _vm.showEditButtons,
                                      showPreviewButton: _vm.showPreviewButton,
                                      showDownloadButton:
                                        _vm.showDownloadButton,
                                    },
                                  })
                                }
                              ),
                              1
                            )
                          : _c(
                              "div",
                              _vm._l(
                                _vm.feedTracksData.data || [],
                                function (track) {
                                  return _c("AudioTrackV2", {
                                    key: track.uid,
                                    attrs: {
                                      sourceComponent: "PODCASTS-TAB",
                                      audioTrack: track,
                                      selectable: true,
                                      selectionType: "MULTI",
                                      editable: _vm.editable,
                                      podcast: _vm.selectedPodcast,
                                      showEditButtons: _vm.showEditButtons,
                                      showPreviewButton: _vm.showPreviewButton,
                                      showDownloadButton:
                                        _vm.showDownloadButton,
                                      isPublishPage: _vm.isPublishPage,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                      ])
                    : _vm.activeTab === "ALL_EPISODES"
                    ? _c("div", [
                        _vm.feedAllTracksLoading
                          ? _c(
                              "div",
                              _vm._l(_vm.customLoaders(), function (_, index) {
                                return _c("div", {
                                  key: index,
                                  staticClass:
                                    "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton unpublished-loader-height",
                                  attrs: { "number:number": "" },
                                })
                              }),
                              0
                            )
                          : _c(
                              "div",
                              _vm._l(_vm.feedAllTrackData, function (episode) {
                                return _c("YoutubeAllEpisodes", {
                                  key: episode.uid,
                                  attrs: {
                                    audioUid: episode.uid,
                                    episode: episode,
                                    podcast: _vm.selectedPodcast,
                                    selectable:
                                      _vm.$permissions.isYoutubeConvertVideoShowEpisodeAllowed(
                                        _vm.selectedPodcast.uid
                                      ),
                                    selectionType: "MULTI",
                                  },
                                })
                              }),
                              1
                            ),
                      ])
                    : _vm.activeTab === "ALL_UNPUBLISHED"
                    ? _c("div", [
                        _vm.feedTracksUnpublishedLoading
                          ? _c(
                              "div",
                              _vm._l(_vm.customLoaders(), function (_, index) {
                                return _c("div", {
                                  key: index,
                                  staticClass:
                                    "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton unpublished-loader-height",
                                  attrs: { "number:number": "" },
                                })
                              }),
                              0
                            )
                          : _c(
                              "div",
                              _vm._l(
                                _vm.feedTracksUnpublishedData,
                                function (episode) {
                                  return _c("YoutubeAllEpisodes", {
                                    key: episode.uid,
                                    attrs: {
                                      audioUid: episode.uid,
                                      episode: episode,
                                      podcast: _vm.selectedPodcast,
                                      selectable:
                                        _vm.$permissions.isYoutubeConvertVideoShowEpisodeAllowed(
                                          _vm.selectedPodcast.uid
                                        ),
                                      selectionType: "MULTI",
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                      ])
                    : _vm._e(),
                ]),
                _vm.showPagination && _vm.pageCount
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "flex justify-end pv3 ph4 bt b--adori-gray bw1",
                      },
                      [
                        _c("BaseButtonRed", {
                          staticClass: "mr3 youtube-link",
                          attrs: {
                            text: "Prev",
                            onClick: _vm.prev,
                            disabled:
                              _vm.podcastPageNumber[_vm.selectedPodcast.uid][
                                _vm.fromYoutube
                                  ? _vm.activeTab
                                  : _vm.selectedPodcastPublishStatus
                              ] === 1,
                          },
                        }),
                        _c("input", {
                          staticClass:
                            "bg-adori-light-gray adori-gray br2 w-38",
                          attrs: { type: "text" },
                          domProps: {
                            value:
                              _vm.podcastPageNumber[_vm.selectedPodcast.uid][
                                _vm.fromYoutube
                                  ? _vm.activeTab
                                  : _vm.selectedPodcastPublishStatus
                              ],
                          },
                          on: {
                            input: function ($event) {
                              return _vm.OnPageNumberChanged(
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("span", { staticClass: "pa2" }, [
                          _vm._v("/ " + _vm._s(_vm.pageCount)),
                        ]),
                        _c("BaseButtonRed", {
                          staticClass: "ml2 youtube-link",
                          attrs: {
                            text: "Next",
                            onClick: _vm.next,
                            disabled:
                              _vm.podcastPageNumber[_vm.selectedPodcast.uid][
                                _vm.fromYoutube
                                  ? _vm.activeTab
                                  : _vm.selectedPodcastPublishStatus
                              ] === _vm.pageCount,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.showConfetti ? _c("TheConfetti") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
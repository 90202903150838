



















































































import { Mixins, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AudioTrack from '@/components/Audio/AudioTrack.vue'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import { ADORI_PODCASTS_BASE_URL } from '@/constants'
import Invalidate from '@/mixins/Invalidate'
import { feedQueryKeys } from '@/hooks/rssFeeds'

@Component({
  components: {
    TheMainPage,
    AudioTrack,
  },
})
export default class PodcastDetailsCard extends Mixins(Invalidate) {
  @Prop({ default: {} })
  podcast!: any

  @Getter rssFeedUids!: any
  @Getter rssFeedAudioTrack!: any
  @Getter rssFeedAudioTrackUids!: any

  @Action pushNotification!: any
  @Action showEditPodcast!: any
  @Action showAddEpisode!: any
  @Action showConfirm!: any
  @Action setShowLoader!: any
  @Action unselectAllAudios!: any
  @Action clearSelectedRssFeedUid!: any
  @Action clearSelectedAudioCollectionId!: any
  @Action deleteRssFeed!: any
  @Action decrementRssFeedsCount!: any
  @Action getAudioUids!: any
  @Action setSelectedRssFeedUid!: any
  @Action closeModal!: any

  get podcastMenu() {
    return [
      {
        name: 'Delete podcast',
        icon: 'delete',
        onClick: () => this.handleDeletePodcast(),
      },
    ]
  }

  get podcastWebsite() {
    const podcastUid = this.podcast.uid
    const podcastNameSlug = this.slugify(this.podcast.name)
    return `${ADORI_PODCASTS_BASE_URL}/${podcastNameSlug}/?cid=${podcastUid}`
  }

  get hasPodcastRSS() {
    return !!this.podcast.rss
  }

  iframeEmbed(episode: any) {
    const episodeUid = episode.uid
    const episodeNameSlug = this.slugify(episode.name)
    return `<iframe src='${ADORI_PODCASTS_BASE_URL}/embed/${episodeNameSlug}?cid=${episodeUid}&header=0' width='100%' height='100%' frameborder='0' scrolling='no' allow='autoplay'></iframe>`
  }

  // get isDeletePodcastAllowed () {
  //   return isDeletePodcastAllowed()
  // }

  slugify(str: string) {
    return (
      str &&
      str
        // Trim leading and trailing white space.
        .trim()
        // Turn everthing to lower case.
        .toLowerCase()
        // Remove non-alphanumeric or non-space characters.
        .replace(/[^a-z0-9 ]/g, '')
        // Replace spaces with hyphens.
        .replace(/ /g, '-')
    )
  }

  copySlugToClipboard() {
    // @ts-ignore
    this.$clipboard(this.iframeEmbed(this.podcast))
    this.pushNotification({ text: 'Copied to clipboard', type: 'SUCCESS' })
  }

  copyRSSToClipboard() {
    // @ts-ignore
    this.$clipboard(this.podcast.rss)
    this.pushNotification({ text: 'Copied to clipboard', type: 'SUCCESS' })
  }

  // isEditPodcastAllowed (feedUid: string) {
  //   return isEditPodcastShowAllowed(feedUid)
  // }

  handleEditPodcast() {
    this.showEditPodcast(this.podcast.uid)
  }

  gotoPodcastWebsite() {
    window.open(this.podcastWebsite, '_blank')
  }

  // isCreateEpisodeAllowed (feedUid: string) {
  //   return isCreateEpisodeShowAllowed(feedUid)
  // }

  handleAddEpisodePodcast() {
    this.showAddEpisode(this.podcast.uid)
  }

  handleDeletePodcast() {
    this.showConfirm({
      title: 'Delete this podcast',
      description:
        'You are about to delete this podcast. You can choose to delete the audio tracks associated with this podcast or keep them.',
      yesButtonText: 'Delete podcast and episodes',
      noButtonText: 'Delete podcast, but keep episodes',
      onConfirm: async () => {
        this.setShowLoader(true)

        this.unselectAllAudios()
        this.clearSelectedRssFeedUid()
        this.clearSelectedAudioCollectionId()

        const nearestRssFeedUid = this.rssFeedUids[0] === this.podcast.uid ? this.rssFeedUids[1] : this.rssFeedUids[0]
        this.setSelectedRssFeedUid(nearestRssFeedUid)

        const audioTrackUids = this.rssFeedAudioTrackUids(this.podcast.uid)

        await this.deleteRssFeed({
          rssFeedUid: this.podcast.uid,
          deleteTracks: true,
        })
        this.queryClient.invalidateQueries(feedQueryKeys.RSSFEED)
        this.closeModal()

        this.setShowLoader(false)
      },
      onCancel: async () => {
        this.setShowLoader(true)

        this.unselectAllAudios()
        this.clearSelectedRssFeedUid()
        this.clearSelectedAudioCollectionId()

        const nearestRssFeedUid = this.rssFeedUids[0] === this.podcast.uid ? this.rssFeedUids[1] : this.rssFeedUids[0]
        this.setSelectedRssFeedUid(nearestRssFeedUid)

        await this.deleteRssFeed({ rssFeedUid: this.podcast.uid })
        this.queryClient.invalidateQueries(feedQueryKeys.RSSFEED)
        this.closeModal()

        this.setShowLoader(false)
      },
    })
  }
}

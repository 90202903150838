var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "darker-hover bb b--adori-gray pb4 waveform pointer",
      on: { click: _vm.handleAudioSelectionIconClicked },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-wrap justify-between ph2 pt4 link darker-hover br2 relative",
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-start" },
            [
              _c(
                "div",
                {
                  staticClass: "flex items-center ml2 mr3",
                  staticStyle: { cursor: "default" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _vm.selectable
                    ? _c(
                        "i",
                        {
                          staticClass: "material-icons f2 white link pointer",
                          class: _vm.selectionStyle + " pointer",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleAudioSelectionIconClicked.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.selectionIcon))]
                      )
                    : _vm._e(),
                ]
              ),
              _c("BaseThumbnailEpisode", {
                staticClass: "mr3",
                attrs: {
                  src: _vm.audioPreviewImage,
                  onClick: _vm.handleAudioSelectionIconClicked,
                },
              }),
              _c(
                "div",
                {
                  staticClass: "flex flex-column flex-wrap",
                  staticStyle: { width: "485px" },
                },
                [
                  _c("div", { staticClass: "flex flex-column" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "f4 overflow-y-hidden truncate light-gray mb1",
                        staticStyle: { width: "485px" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.audio
                                ? _vm.audio.name
                                : "Loading audio details..."
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm.audio
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-wrap justify-left items-center mt2",
                          staticStyle: { width: "485px" },
                        },
                        [
                          _c("div", { staticClass: "f6 light-gray mt1" }, [
                            _c("span", [_vm._v(_vm._s(_vm.audioDate))]),
                            _vm._v("\n            ·\n            "),
                            _c("span", [_vm._v(_vm._s(_vm.audioDuration))]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
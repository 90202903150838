var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bb b--adori-gray pb4 waveform darker-bg relative" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-wrap justify-between ph2 pt4 link br2 relative",
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-start" },
            [
              _c(
                "div",
                {
                  staticClass: "flex items-center ml2 mr3",
                  staticStyle: { cursor: "default" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _vm.selectable
                    ? _c(
                        "i",
                        {
                          staticClass: "material-icons f2 white link pointer",
                          class: _vm.selectionStyle + " pointer",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleAudioSelectionIconClicked.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.selectionIcon))]
                      )
                    : _vm._e(),
                ]
              ),
              _c("BaseThumbnailEpisode", {
                staticClass: "mr3",
                attrs: { src: _vm.audioPreviewImage },
              }),
              _c(
                "div",
                {
                  staticClass: "flex flex-column flex-wrap",
                  staticStyle: { width: "485px" },
                },
                [
                  _c("div", { staticClass: "flex flex-column" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "f4 overflow-y-hidden truncate light-gray mb1",
                        staticStyle: { width: "485px" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.audioTrack
                                ? _vm.audioTrack.name
                                : "Loading audio details..."
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm.audioTrack
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-wrap justify-left items-center mt2",
                          staticStyle: { width: "485px" },
                        },
                        [
                          _c("div", { staticClass: "f6 light-gray mt1" }, [
                            _c("span", [_vm._v(_vm._s(_vm.audioDate))]),
                            _vm._v("\n            ·\n            "),
                            _c("span", [_vm._v(_vm._s(_vm.audioDuration))]),
                            _vm._v("\n            ·\n            "),
                            _c("span", [_vm._v(_vm._s(_vm.audioTagsText))]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.audioTrack
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-wrap justify-left items-center mt2",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex flex-wrap items-center" },
                            _vm._l(_vm.podcastsWithAudio, function (pod) {
                              return _c(
                                "div",
                                {
                                  key: pod.uid,
                                  staticClass:
                                    "f7 mr2 mv1 br2 flex items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bg-adori-gray light-gray ph1 pv1 br2",
                                      class:
                                        pod &&
                                        _vm.audioTrack.season &&
                                        _vm.audioTrack.episodeNumber
                                          ? "br--left"
                                          : "",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.truncate(pod.name, 20)) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  pod &&
                                  (_vm.audioTrack.season ||
                                    _vm.audioTrack.episodeNumber)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "bg-adori-red light-gray ph1 pv1 br2 br--right",
                                        },
                                        [
                                          _vm.audioTrack.season
                                            ? _c("span", [
                                                _vm._v(
                                                  " S" +
                                                    _vm._s(
                                                      _vm.audioTrack.season +
                                                        " "
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.audioTrack.episodeNumber
                                            ? _c("span", [
                                                _vm._v(
                                                  " E" +
                                                    _vm._s(
                                                      _vm.audioTrack
                                                        .episodeNumber
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm.showEditButtons
            ? _c(
                "div",
                {
                  staticClass:
                    "flex flex-column items-center absolute right-1 top-2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex justify-end",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      (
                        _vm.hasSelectedPodcast
                          ? _vm.$permissions.isEditEpisodeShowAllowed(
                              _vm.podcast.uid
                            )
                          : _vm.$permissions.isEditEpisodeAllowed()
                      )
                        ? _c("BaseButtonBorder", {
                            staticClass: "mr3",
                            attrs: {
                              icon: "ondemand_video",
                              text: "Create Video",
                              onClick: _vm.goToOnboarding,
                            },
                          })
                        : _vm._e(),
                      (
                        _vm.hasSelectedPodcast
                          ? _vm.$permissions.isEditEpisodeShowAllowed(
                              _vm.podcast.uid
                            )
                          : _vm.$permissions.isEditEpisodeAllowed()
                      )
                        ? _c("ButtonAudioAdorify", {
                            staticClass: "mr3 mb0 w-fixed",
                            attrs: { audioUid: _vm.audioTrack.uid },
                          })
                        : _vm._e(),
                      _vm.audioTrackMenu.length
                        ? _c("BasePopupMenu", {
                            attrs: {
                              icon: "more_vert",
                              items: _vm.hasSelectedPodcast
                                ? _vm.podcastEpisodeMenu
                                : _vm.audioTrackMenu,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.showPreviewButton
            ? _c("div", [
                _vm.isPublishPage
                  ? _c("div", { staticClass: "flex flex-column items-end" }, [
                      _c(
                        "div",
                        { staticClass: "flex items-center mr3 mb3" },
                        [
                          _c("BaseButtonBorder", {
                            staticClass: "mr3",
                            attrs: {
                              text: "Episode website",
                              onClick: _vm.openEpisodeWebsite,
                            },
                          }),
                          _c("BaseButtonBorder", {
                            attrs: {
                              href: _vm.portraitExperienceSrc,
                              text: "Embed interactive web-player",
                              onClick: _vm.handleIframeEmbed,
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm.formatedPublishedOrScheduled
                  ? _c(
                      "div",
                      { staticClass: "ph4 f6 mt5 flex justify-end light-gray" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.formatedPublishedOrScheduled) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.audioIsAdorified
            ? _c(
                "div",
                {
                  staticClass:
                    "absolute top-0 left-0 white bg-adori-red f7 ph2 pv1 fw5 tracked",
                },
                [_vm._v("\n      Interactive\n    ")]
              )
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "flex justify-between pr3 mr2" }, [
        _c("div", { staticClass: "ml174" }, [
          _vm.firstClip && !_vm.isPublishPage
            ? _c(
                "div",
                [
                  _c("YoutubeUploadStatus", {
                    key: _vm.firstClip.id,
                    attrs: {
                      clipId: _vm.firstClip.id,
                      audioTrack: _vm.audioTrack,
                      youtubeSignInStatus: _vm.youtubeSignInStatus,
                      podcast: _vm.podcast,
                      isFirstClip: "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm.showComboBox && !_vm.isPublishPage
          ? _c(
              "div",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    _vm.isComboBoxActive = !_vm.isComboBoxActive
                  },
                },
              },
              [
                _vm.isComboBoxActive
                  ? _c("div", [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons v-mid white text-shadow mt2",
                        },
                        [_vm._v(" expand_less ")]
                      ),
                    ])
                  : _c("div", [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons v-mid white text-shadow mt3",
                        },
                        [_vm._v(" expand_more ")]
                      ),
                    ]),
              ]
            )
          : _vm._e(),
      ]),
      _vm.showComboBox && !_vm.isPublishPage
        ? _c("div", [
            _vm.isComboBoxActive
              ? _c(
                  "div",
                  { staticClass: "combobox pb3 pt1" },
                  _vm._l(_vm.allVideoClipsExceptFirst, function (clip) {
                    return _c("YoutubeUploadStatus", {
                      key: clip.id,
                      attrs: {
                        clipId: clip.id,
                        audioTrack: _vm.audioTrack,
                        youtubeSignInStatus: _vm.youtubeSignInStatus,
                        podcast: _vm.podcast,
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.audioIsGettingReplaced
        ? _c(
            "div",
            {
              staticClass:
                "absolute top-0 left-0 w-100 h-100 flex items-center justify-center br2 f4",
              staticStyle: { background: "rgb(0, 0, 0, 0.85)" },
              on: {
                click: [
                  function ($event) {
                    $event.preventDefault()
                  },
                  function ($event) {
                    $event.stopPropagation()
                  },
                ],
              },
            },
            [
              _vm._v("\n    Audio is being replaced\n    "),
              _c("div", { staticClass: "loader loader-inline ml3" }),
            ]
          )
        : _vm._e(),
      _vm.shareAudio
        ? _c("ShareModal", {
            attrs: { audio: _vm.audioTrack },
            on: { close: _vm.closeShare },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }







import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { createRssFeedPoll, destroyRssFeedPoll } from '@/store/rssFeeds'
import { ADORI_PODCASTS_BASE_URL } from '@/constants'

@Component
export default class PodcastSelector extends Vue {
  @Prop(String) podcastUid!: any

  @Getter selectedRssFeedUid!: any
  @Getter rssFeed!: any
  @Getter rssFeedUids!: any
  @Getter rssFeedsByUid!: any
  @Getter defaultAudioCollection!: any

  @Action unselectAllAudios!: any
  @Action clearSelectedRssFeedUid!: any
  @Action clearSelectedAudioCollectionId!: any
  @Action setSelectedRssFeedUid!: any
  @Action showAddEpisode!: any
  @Action showEditPodcast!: any
  @Action showPublishPodcast!: any
  @Action deleteRssFeed!: any
  @Action setSelectedAudioCollectionId!: any

  @Action showConfirm!: any
  @Action closeModal!: any

  podcastPollHandler: number | null = null
  podcastPollInterval: number = 8000 // ms

  showSearchTray: boolean = false

  async mounted() {
    if (this.podcastImportStatus === 'QUEUED' || this.podcastImportStatus === 'STARTED') {
      this.podcastPollHandler = createRssFeedPoll(
        this.$store,
        { rssFeedUid: this.podcast.uid },
        this.podcastPollInterval
      )
    }
  }

  beforeDestroy() {
    if (this.podcastPollHandler) {
      destroyRssFeedPoll(this.podcastPollHandler)
    }
  }

  @Watch('podcastImportStatus')
  onSuggestionsStatusChanged() {
    if ((this.podcastImportStatus === 'FINISHED' || this.podcastImportStatus === 'FAILED') && this.podcastPollHandler) {
      destroyRssFeedPoll(this.podcastPollHandler)
    }
  }

  get podcast() {
    return this.rssFeed(this.podcastUid)
  }

  get podcastImportStatus() {
    if (this.podcast && this.podcast.importTask) {
      return this.podcast.importTask.status
    }
    return 'NOT_IMPORTED'
  }

  get podcastImportProgress() {
    if (this.podcast.importTask) {
      const totalTracks = this.podcast.importTask.totalTracks
      const tracksImported = this.podcast.importTask.tracksImported || 0

      return `Imported ${tracksImported} / ${totalTracks} episodes`
    }
  }

  get isImportingPodcast() {
    return (
      !!this.podcast.importedFrom &&
      this.podcast.importTask.status !== 'FINISHED' &&
      this.podcast.importTask.status !== 'FAILED'
    )
  }

  get hasFailedImport() {
    return !!this.podcast.importedFrom && this.podcast.importTask.status === 'FAILED'
  }

  get podcastImageSrc() {
    return this.rssFeed(this.podcastUid).squareImageInfo && this.rssFeed(this.podcastUid).squareImageInfo.url
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }
}

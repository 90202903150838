






















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { toReadableDate } from '@/utils/time'
import ProgressBar from 'vuejs-progress-bar'
import { pluralize } from '@/utils/misc'
import { uploadStatus } from '@/components/Publish/publish'
import { secondsToHMS } from '@/components/Youtube/time'
import get from 'lodash.get'

import YoutubeShare from '@/components/Youtube/Modal/ModalYoutubeShare.vue'
// service
import AdoriService from '@/services/adori'
import { audioQueryKeys, useGetVideoClipsById } from '@/hooks/audio'
import { ref } from '@vue/composition-api'
import { QueryClient, useQueryClient } from 'vue-query'
import moment from 'moment'
import mixpanel from 'mixpanel-browser'

// @ts-ignore
const webengage = window['webengage']
@Component({
  components: {
    ProgressBar,
    YoutubeShare,
  },
  setup({ clipId, youtubeSignInStatus }: any) {
    const interval: any = ref(false)
    const queryClient = useQueryClient()
    const { data: clip, isLoading: clipLoading } = useGetVideoClipsById(clipId, youtubeSignInStatus, interval)
    return {
      clip,
      clipLoading,
      interval,
      queryClient,
    }
  },
})
export default class YoutubeUploadStatus extends Vue {
  @Prop(Object) audioTrack!: any
  @Prop() clipId!: any
  @Prop(Boolean) isFirstClip!: boolean
  @Prop() podcast!: any
  @Prop(Boolean) youtubeSignInStatus!: boolean

  @Getter networkId!: string
  @Getter publicFeedEpisodes!: any
  @Getter isYoutube!: boolean

  @Action youtubeUploadStatus!: any
  @Action pushNotification!: any
  @Action getPublicFeedEpisodes!: any
  @Action closeModal!: any
  @Action clearAllRssFeedItemsByFeedUid!: any
  @Action updateRssFeedItems!: any

  pluralize = pluralize

  clip!: any
  clipLoading!: any
  interval!: any

  shareAudio = false
  uploadError = null
  newLoader = false
  loading = false

  youtubeError = false
  youtubeProcessing = false
  youtubeText = 'View'
  confettiStatus = true
  currentAudioDetails = null
  queryClient!: QueryClient

  @Watch('clip')
  watchClip() {
    if (
      (this.clip.status == uploadStatus.QUEUED ||
        this.clip.status == uploadStatus.STARTED ||
        this.clip?.youtubeProcessingDetails?.processingStatus === 'processing') &&
      this.confettiStatus
    ) {
      this.$store.commit('addUploadToCounter', this.clip.id)
      this.confettiStatus = false
    }
    this.youtubeProcess()
  }

  mounted() {
    if (this.clip.status == uploadStatus.QUEUED || this.clip.status == uploadStatus.STARTED) {
      this.$store.commit('addUploadToCounter', this.clip.id)
    }
    this.youtubeProcess()
  }

  youtubeLink() {
    window.open('//youtu.be/' + this.clip?.videoId, '_blank')
  }

  get progressBarValue() {
    return Math.ceil(this.clip.progress)
  }

  get youtubeLinkShare() {
    return `https://youtu.be/${this.clip?.videoId}`
  }

  get showYoutubeLink() {
    return this.clip?.videoId && this.clip.youtubeProcessingDetails ? true : false
  }

  get isScheduled() {
    return this.clip?.status === uploadStatus.SCHEDULED ? true : false
  }
  get scheduledFor() {
    if (this.clip?.scheduledFor) {
      return (
        'Scheduled For:' + toReadableDate(this.clip?.scheduledFor) + ' , ' + this.toLocalTime(this.clip?.scheduledFor)
      )
    }
  }

  toLocalTime(timeString: string) {
    let date = new Date(timeString).toString()
    let time = date.split(' ')[4].substring(0, 5)
    let offset = date.split(' ')[5]
    return time + ' ' + offset
  }

  get showProgressBar() {
    if (this.clip.status === uploadStatus.FINISHED) return false
    else if (this.clip.status == uploadStatus.QUEUED || this.clip.status == uploadStatus.STARTED) return true
    return false
  }

  get showDeleteButton() {
    return (
      moment().diff(moment(this.clip.createdOn), 'hour') >= 2 &&
      [uploadStatus.STARTED, uploadStatus.QUEUED].includes(this.clip.status)
    )
  }

  get showPopupAction() {
    return this.showProgressBar || this.isScheduled ? false : true
  }

  get audioDuration() {
    if (this.clip?.duration) {
      return secondsToHMS(this.clip?.duration)
    }
    return 'Calculating...'
  }

  get audioDate() {
    if (this.clip?.publishedOn) {
      return toReadableDate(this.clip?.publishedOn)
    }

    if (this.clip?.publishTime) {
      return toReadableDate(this.clip?.publishTime)
    }
    return toReadableDate(this.clip?.createdOn)
  }

  get audioIsPublic() {
    return this.clip.privacy
  }

  get audioTags() {
    return this.clip ? this.clip?.tagCount : 0
  }

  get audioTrackMenu() {
    const menu: any = []
    this.$permissions.isYoutubeConvertVideoShowEpisodeAllowed(get(this.podcast, 'uid', '')) &&
      menu.push({
        name: 'New Video',
        icon: 'ondemand_video',
        onClick: () => this.goToOnboarding(),
      })

    menu.push({
      name: 'Download Video',
      icon: 'download',
      onClick: () => this.downloadVideo(),
    })

    this.$permissions.isDeleteEpisodeShowAllowed(get(this.podcast, 'uid', '')) &&
      menu.push({
        name: 'Remove Clip',
        icon: 'delete',
        onClick: () => this.removeVideo(),
      })
    this.showYoutubeLink &&
      menu.push({
        name: 'Copy YouTube Link',
        icon: 'content_copy',
        onClick: () => this.copyYoutubeLink(),
      })

    return menu
  }

  get cYoutubeLink() {
    return 'https://youtu.be/' + this.clip?.videoId
  }

  youtubeProcess() {
    if (this.clip.status === uploadStatus.FINISHED) {
      if (this.clip?.youtubeProcessingDetails === null && this.clip.publishToYoutube && this.interval) {
        this.youtubeError = true
        this.pushNotification({
          text: `There was a problem uploading to youtube <a href="https://help.adorilabs.com/en/collections/3509519-adori-video-and-studio">Learn More</a>`,
          type: 'ERROR',
        })
        this.$store.commit('removeUploadFromCounter', this.clip.id)
        this.interval = false
      } else if (this.clip?.youtubeProcessingDetails === null && !this.clip.publishToYoutube) {
        this.$store.commit('removeUploadFromCounter', this.clip.id)
        if (this.interval !== false) this.downloadVideo()
        this.interval = false

        // this.downloadVideo()
      } else if (this.clip?.youtubeProcessingDetails?.processingStatus === 'processing') {
        this.youtubeText = 'Processing'
        this.youtubeProcessing = true
        this.interval = 7000
      } else if (this.clip?.youtubeProcessingDetails?.processingStatus === 'succeeded') {
        this.youtubeText = 'View'
        this.youtubeProcessing = false
        this.$store.commit('removeUploadFromCounter', this.clip.id)
        this.interval = false
      }
    } else if (this.clip.status == uploadStatus.QUEUED || this.clip.status == uploadStatus.STARTED) {
      this.interval = 7000
    }
  }

  copyYoutubeLink() {
    // @ts-ignore
    this.$clipboard(this.cYoutubeLink)
    this.pushNotification({ text: 'Copied to clipboard', type: 'SUCCESS' })
  }

  openShare() {
    this.shareAudio = true
  }

  closeShare() {
    this.shareAudio = false
  }

  async downloadVideo() {
    const params = {
      network_id: this.networkId,
      task_id: this.clip.id,
    }

    const data: any = await AdoriService.youtube_download(params)
    if (!data.url) {
      this.pushNotification({ text: 'No URL available', type: 'ERROR' })
      return
    }
    if (this.$refs['videoDownloadRef']) {
      //@ts-ignore
      this.$refs['videoDownloadRef'].href = data.url
      const audioDownloadLink = this.$refs.videoDownloadRef as HTMLElement
      audioDownloadLink.click()
    }
  }

  async removeVideo() {
    const params = {
      network_id: this.networkId,
      task_id: this.clip.id,
    }

    this.$store.dispatch('showConfirm', {
      title: 'Remove video clip?',
      description: 'Are you sure you want to remove the video?',
      yesButtonText: 'Yes',
      onConfirm: async () => {
        try {
          this.closeModal()
          await AdoriService.youtube_upload_delete(params)
          this.pushNotification({ text: 'Video removed', type: 'SUCCESS' })
          this.queryClient.invalidateQueries(audioQueryKeys.TRACKVIDEOCLIPS)
        } catch (err) {}
        this.closeModal()
      },
    })
  }

  async goToOnboarding() {
    mixpanel.track('New Video clip')
    this.$gtag.event('new-video-clip', { action: 'Video clip copy will be created' })
    webengage?.track('new-video-clip', { action: 'Video clip copy will be created' })
    const uid = this.$route.params.id
    if ((this.isYoutube && this.audioTrack && this.audioTrack.ytFeed === null) || (!this.isYoutube && !uid)) {
      this.newLoader = true
      const selectedEpisode: any = {
        audioUrl: this.audioTrack.audioUrl,
        name: this.audioTrack.name,
        description: this.audioTrack.description,
        explicitContent: this.audioTrack.explicitContent,
        guid: this.audioTrack.guid,
        imageId: this.audioTrack.imageId,
        imageUrl: this.audioTrack.imageUrl,
        isRss: false,
        keywords: this.audioTrack.keywords,
        durationSeconds: this.audioTrack.durationMillis / 1000,
        isReUpload: true,
        startTimeSec: this.clip.startTimeSec,
        endTimeSec: this.clip.endTimeSec,
        width: this.clip.width,
        height: this.clip.height,
        privacy: this.clip.privacy,
        playlistId: this.clip.playlistId,
        isMadeForKids: this.clip.selfDeclareMadeForKids,
        category: this.clip.categoryId,
        videoUrl: this.audioTrack.videoUrl,
      }

      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)

      sessionStorage.setItem('upload', 'YES')
      sessionStorage.setItem('onboardingTrack', JSON.stringify(selectedEpisode))
      this.$router.push('/onboarding')
      this.newLoader = false
    } else {
      this.newLoader = true
      this.$store.commit('setCreatedYoutubeFeed', this.isYoutube ? this.audioTrack.ytFeed : { uid: uid })
      sessionStorage.setItem('feedUid', this.isYoutube ? this.audioTrack.ytFeed.uid : uid)
      await this.getPublicFeedEpisodes({
        url: this.isYoutube ? this.audioTrack.ytFeed.rss : this.podcast.rss,
      })
      sessionStorage.setItem('feedUrl', this.isYoutube ? this.audioTrack.ytFeed.rss : this.podcast.rss)
      const selectedEpisode = this.publicFeedEpisodes.filter((obj: any) => obj.guid === this.audioTrack.guid)[0]
      if (!selectedEpisode) {
        this.pushNotification({
          text: 'Please try again later',
          type: 'WARNING',
        })
        return
      }
      selectedEpisode['width'] = this.clip.width
      selectedEpisode['height'] = this.clip.height
      selectedEpisode['startTimeSec'] = this.clip.startTimeSec
      selectedEpisode['endTimeSec'] = this.clip.endTimeSec
      selectedEpisode['isRss'] = true
      selectedEpisode['youtubeKeywords'] = this.clip.keywords
      selectedEpisode['name'] = this.clip.title
      selectedEpisode['description'] = this.clip.description
        .slice(0, this.clip.description.indexOf('\n\nPowered by Adori'))
        .slice(0, this.clip.description.indexOf('\n\n⌛Timestamps:'))
      selectedEpisode['playlistId'] = this.clip.playlistId
      selectedEpisode['category'] = this.clip.categoryId
      selectedEpisode['privacy'] = this.clip.privacy
      selectedEpisode['isMadeForKids'] = this.clip.selfDeclareMadeForKids

      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)

      sessionStorage.setItem('upload', 'YES')

      sessionStorage.removeItem('onboardingTrack')
      sessionStorage.removeItem('ytModalSetting')
      this.$router.push('/onboarding')
      this.newLoader = false
    }
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  options = {
    text: {
      color: '#FFFFFF',
      shadowEnable: true,
      shadowColor: '#000000',
      fontSize: 8,
      fontFamily: 'Helvetica',
      dynamicPosition: false,
      hideText: true,
    },
    progress: {
      color: '#de1a23',
      backgroundColor: 'grey',
      inverted: false,
    },
    layout: {
      height: 10,
      verticalTextAlign: 61,
      horizontalTextAlign: 43,
      zeroOffset: 0,
      strokeWidth: 30,
      progressPadding: 0,
      type: 'line',
    },
  }
}






























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component({
  components: {},
})
export default class PodcastDirectories extends Vue {
  get windowLoaction() {
    return window.location.origin
  }

  handlePodcastDirectories(service: string) {
    switch (service) {
      case 'apple':
        window.open('https://itunesconnect.apple.com/login?module=PodcastsConnect', '_blank')
        break
      case 'spotify':
        window.open('https://podcasters.spotify.com/', '_blank')
        break
      case 'google':
        window.open('https://play.google.com/music/podcasts/publish', '_blank')
        break
      case 'listennotes':
        window.open('https://www.listennotes.com/submit/', '_blank')
        break
      case 'pocketcasts':
        window.open('https://www.pocketcasts.com/submit/', '_blank')
        break
      case 'breaker':
        window.open('https://www.breaker.audio/i/upstream', '_blank')
        break
      case 'castbox':
        window.open(' https://castbox.fm/creator/channels', '_blank')
        break
      case 'overcast':
        window.open('https://overcast.fm/podcasterinfo', '_blank')
        break
      case 'radiopublic':
        window.open('https://podcasters.radiopublic.com/', '_blank')
        break
      case 'iheart':
        window.open('https://podcasters.iheart.com/', '_blank')
        break
      case 'stitcher':
        window.open('https://partners.stitcher.com/', '_blank')
        break
    }
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-adori-light-gray br2 ba b--adori-gray white pa4 mb3" },
    [
      _c("div", { staticClass: "flex justify-between items-center mb4" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _vm.podcast.squareImageInfo &&
          _vm.podcast.squareImageInfo.thumbnailURL
            ? _c("img", {
                staticClass: "w3 h3 mr3",
                attrs: { src: _vm.podcast.squareImageInfo.thumbnailURL },
              })
            : _c(
                "i",
                {
                  staticClass:
                    "material-icons light-gray f3 br2 bg-adori-very-light-gray flex justify-center items-center w2 h2 ml2 mr3",
                },
                [_vm._v("audiotrack")]
              ),
          _c("div", { staticClass: "flex flex-column" }, [
            _vm.podcast.name
              ? _c("span", { staticClass: "f3 fw1 light-gray mb2" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.podcast.name) + "\n        "
                  ),
                ])
              : _vm._e(),
            _vm.podcast.publisher
              ? _c("span", { staticClass: "f5 silver" }, [
                  _vm._v(" By " + _vm._s(_vm.podcast.publisher) + " "),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex items-center" },
          [
            _vm.$permissions.isEditPodcastShowAllowed(_vm.podcast.uid)
              ? _c("BaseButtonBorder", {
                  staticClass: "mr3",
                  attrs: {
                    icon: "edit",
                    text: "Edit podcast",
                    onClick: _vm.handleEditPodcast,
                  },
                })
              : _vm._e(),
            _vm.$permissions.isCreateEpisodeShowAllowed(_vm.podcast.uid)
              ? _c("BaseButtonRed", {
                  attrs: {
                    icon: "add",
                    text: "Add an episode",
                    onClick: _vm.handleAddEpisodePodcast,
                  },
                })
              : _vm._e(),
            _vm.$permissions.isDeletePodcastAllowed()
              ? _c("BasePopupMenu", {
                  attrs: { icon: "more_vert", items: _vm.podcastMenu },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flex justify-start items-center cf" }, [
        _c("div", { staticClass: "flex flex-column mr4 fl w-third" }, [
          _c("div", { staticClass: "light-gray mb2" }, [
            _vm._v("Embed on Website"),
          ]),
          _c(
            "div",
            { staticClass: "flex items-center" },
            [
              _c("input", {
                staticClass:
                  "db bg-adori-very-light-gray bw0 br2 ph2 pv2 f6 white flex-auto br--left w-100",
                attrs: { disabled: "" },
                domProps: { value: _vm.iframeEmbed(_vm.podcast) },
              }),
              _c("BaseButtonRed", {
                staticClass: "br--right",
                attrs: { icon: "assignment", onClick: _vm.copySlugToClipboard },
              }),
            ],
            1
          ),
        ]),
        _vm.hasPodcastRSS
          ? _c("div", { staticClass: "flex flex-column mr4 fl w-third" }, [
              _c("div", { staticClass: "light-gray mb2" }, [
                _vm._v("RSS link"),
              ]),
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _c("input", {
                    staticClass:
                      "db bg-adori-very-light-gray bw0 br2 ph2 pv2 f6 white flex-auto br--left w-100",
                    attrs: { disabled: "" },
                    domProps: { value: _vm.podcast.rss },
                  }),
                  _c("BaseButtonRed", {
                    staticClass: "br--right",
                    attrs: {
                      icon: "assignment",
                      onClick: _vm.copyRSSToClipboard,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.hasPodcastRSS
          ? _c("div", { staticClass: "flex flex-column mr4 fl w-third" }, [
              _c("div", { staticClass: "light-gray mb2" }, [
                _vm._v("Show website"),
              ]),
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _c("input", {
                    staticClass:
                      "db bg-adori-very-light-gray bw0 br2 ph2 pv2 f6 white flex-auto br--left w-100",
                    attrs: { disabled: "" },
                    domProps: { value: _vm.podcastWebsite },
                  }),
                  _c("BaseButtonRed", {
                    staticClass: "br--right",
                    attrs: {
                      icon: "open_in_new",
                      onClick: _vm.gotoPodcastWebsite,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }